.stages {
  margin-bottom: var(--margin-bottom);
}

.stage-card {
  box-shadow: var(--box-shadow-grid);
  border-left: 4px solid var(--accent);
  padding: 15px 20px;
  border-radius: 4px;
  transition: transform 0.2s;

  max-width: 900px;
  margin: 20px auto;
}

.stage-card:hover {
  transform: translateX(10px);
  box-shadow: var(--box-shadow-grid-hover);
}

.stage-title {
  /* color: var(--accent); */
  font-weight: 700;
  font-size: clamp(1.125rem, 1.022rem + 0.47vw, 1.375rem);
  margin: 0 0 10px 0;
}

.stage-description {
  margin: 0;
  line-height: 1.5;
}

.stage-time {
  color: #666;
  font-size: 14px;
  margin-top: 8px;
}
