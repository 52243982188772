/* Base */ /* Reset and base styles  */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
* {
  padding: 0px;
  margin: 0px;
  border: none;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Links */
a,
a:link,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Common */
aside,
nav,
footer,
header,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
}

ul,
ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

/* Form */
input,
textarea,
button,
select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button,
input[type="submit"] {
  display: inline-block;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus,
input:active,
button:focus,
button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

:root {
  --container-width: 1200px;
  --container-padding: 15px;
  --font-main: "Roboto", sans-serif;
  --page-bg: #fff;
  --text-color: #171718;
  --text-muted: #909090;
  --accent: #5c62ec;
  --text-white: #fff;
  --dark-bg: #171718;
  --margin-bottom: 80px;
  --border: #9090909e;
  --box-shadow-grid: 0 1px 3px rgba(0, 0, 0, 0.3);
  --box-shadow-grid-hover: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.accent {
  color: var(--accent);
}
.dark {
  --page-bg: #1c1c1c;
  --text-color: var(--text-white);
  --title-1: var(--text-white);
  --project-card-bg: var(--page-bg);
  --project-card-text: var(--text-white);
  --box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8);
  --box-shadow-grid: 0 1px 3px rgb(252 252 252 / 0.4);
  --box-shadow-grid-hover: 0 4px 6px rgb(252 252 252 / 0.4);
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--page-bg);
  color: var(--text-color);
  font-family: var(--font-main);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: block;
}

.title-1 {
  color: var(--accent);
  font-size: clamp(2.188rem, 1.619rem + 2.84vw, 3.75rem); /* 35 - 60 */
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 78px */
  letter-spacing: -0.5px;
  text-align: center;
  margin-bottom: 60px;
}

.title-1--home {
  font-size: clamp(
    1.875rem,
    1.42rem + 2.27vw,
    3.125rem
  ) !important; /* 30 - 50 */
  margin-bottom: 40px !important;
}

@media (max-width: 620px) {
  .title-1--home {
    margin-bottom: 30px;
    margin-bottom: 20px !important;
  }

  .title-1{
    margin-bottom: 30px;
  }
}

.title-2 {
  margin-bottom: 10px;
  font-size: 30px;
}
@media (max-width: 620px) {
  .title-2 {
    /* margin-bottom: 30px; */
    font-size: 20px;
  }
}

.none {
  display: none !important;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.no-scroll {
  overflow-y: hidden;
}

.container {
  padding: 0 var(--container-padding);
  max-width: var(--container-width);
  margin: 0 auto;
}

.container-right {
  /* overflow-x: hidden; */
  padding-left: calc(
    (100% - var(--container-width)) / 2 + var(--container-padding)
  );
}
@media (max-width: 1229px) {
  .container-right {
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
  }
}

.container-left {
  /* overflow-x: hidden; */
  padding-right: calc(
    (100% - var(--container-width)) / 2 + var(--container-padding)
  );
}
@media (max-width: 1229px) {
  .container-left {
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
  }
}

html,
body {
  min-height: 100vh;
}

.App {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

/* Blocks */
.nav {
  padding: 20px 0;
  border: 1px solid #26292d;
  background-color: var(--dark-bg);
}

.nav-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
  row-gap: 20px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (max-width: 650px) {
  .nav-row {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.logo {
  color: var(--text-white);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  row-gap: 10px;
  -webkit-column-gap: 40px;
  -moz-column-gap: 40px;
  column-gap: 40px;
}

.nav-list__link {
  color: var(--text-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.nav-list__item:hover {
  opacity: 0.8;
}

.nav-list__link--active {
  position: relative;
}

.nav-list__link--active::before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  display: block;
  height: 2px;
  width: 100%;
  background-color: var(--accent);
  -webkit-animation: scale-out-hor-left 0.5s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) reverse both;
  animation: scale-out-hor-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    reverse both;
}

@-webkit-keyframes scale-out-hor-left {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes scale-out-hor-left {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}
.header {
  padding: 40px 0;
  min-height: 695px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--dark-bg);
  color: var(--text-white);
  text-align: center;
  position: relative;
}
@media (max-width: 620px) {
  .header {
    min-height: unset;
  }
}

.header::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("./../../img/header-bg.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center center;
  z-index: 1;
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.header__wrapper {
  padding: 0 15px;
  max-width: 830px;
  z-index: 2;
}

.header__title {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.4;
}
@media (max-width: 620px) {
  .header__title {
    font-size: 30px;
  }
}

.header__title strong {
  font-size: 60px;
  font-weight: 700;
}
@media (max-width: 620px) {
  .header__title strong {
    font-size: 40px;
  }
}

.header__title em {
  font-style: normal;
  color: var(--accent);
}

.header__text {
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 1.333;
}

.header__text p + p {
  margin-top: 0.5em;
}

/* anim */
@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
.btn {
  display: inline-block;
  height: 48px;
  padding: 12px 28px;
  border-radius: 5px;
  background-color: var(--accent);
  color: var(--text-white);
  letter-spacing: 0.15px;
  font-size: 16px;
  font-weight: 500;
}

.btn:hover {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

.btn:active {
  position: relative;
  top: 1px;
}

.btn-outline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 48px;
  padding: 12px 20px;
  border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #000;
  background-color: var(--text-white);
  color: #000;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
}

.btn-outline:hover {
  opacity: 0.7;
}

.btn-outline:active {
  position: relative;
  top: 1px;
}

.section {
  padding: 70px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media (max-width: 620px) {
  .section {
    padding: 40px 0;
  }
}

.advantages {
  margin-bottom: var(--margin-bottom);
}

.advantages-list {
  font-size: clamp(0.938rem, 0.847rem + 0.45vw, 1.188rem); /*19 - 15 */

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

@media (max-width: 690px) {
  .advantages-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

.advantages-list li {
  box-shadow: var(--box-shadow-grid);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  gap: 30px;

  padding: 1.5rem;
  border-radius: 0.5rem;
  transition: box-shadow 0.3s;
}

.advantages-list li:hover {
  box-shadow: var(--box-shadow-grid-hover);
}

.advantages-list li h3 {
  font-weight: 700;
  font-size: clamp(1.125rem, 1.022rem + 0.47vw, 1.375rem); /*22 - 18 */
}

.advantages-list li div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 550px) {
  .advantages-list li {
    gap: 15px;
  }
}

.advantages-list li img {
  width: 60px;
}
.services {
  margin-bottom: var(--margin-bottom);
}

.services-text {
  font-size: 21px;
  text-align: center;
  max-width: 900px;
  margin: 0 auto 2rem auto;
}

@media (max-width: 620px) {
  .services-text {
    font-size: 18px;
  }
}
.services-text p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.services-text a {
  color: var(--accent);
  transition: color 0.3s;
}

.services-text a:hover {
  opacity: 0.7;
}

.services-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

/* Адаптивная сетка */
@media (min-width: 768px) {
  .services-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .services-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

.services-list li {
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow-grid);
  transition: box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.services-list li:hover {
  box-shadow: var(--box-shadow-grid-hover);
}

/* Стили для иконок */
.services-list svg {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
  color: #3b82f6;
}

.services-list h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.services-list p {
  font-size: 0.875rem;
  margin: 0;
}

/* Стили для кнопки "Подробнее" */

.services-more {
  max-width: 200px;
  margin: 0 auto;
}
.services-more a {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  box-shadow: none;

  color: var(--accent);
  margin-top: 20px;
}

.services-more a:hover {
  opacity: 0.7;
}

.services-more a svg {
  width: 1rem;
  height: 1rem;
  margin: 0;
}

.services-list a {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--accent);
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s;
}

.services-list a:hover {
  color: #2563eb;
}

.services-list a svg {
  width: 1rem;
  height: 1rem;
  margin: 0;
}

.footer {
  margin-top: auto;
  padding: 60px 0 50px;
  color: var(--text-white);
  background-color: var(--dark-bg);
  max-height: 200px;
}
@media (max-width: 620px) {
  .footer {
    padding: 40px 0 30px;
  }
}

.footer__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  row-gap: 27px;
}
@media (max-width: 620px) {
  .footer__wrapper {
    row-gap: 20px;
  }
}

.social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 620px) {
  .social {
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
  }
}

@media (max-width: 620px) {
  .social__item {
    width: 28px;
  }
}
.social__item:hover {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
.project-details {
  margin: 0 auto;
  max-width: 865px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.project-details__cover {
  max-width: 100%;
  margin-bottom: 40px;
  -webkit-box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.project-details__desc {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.3;
}
.project-details__desc span {
  font-weight: 700;
  /* text-decoration: underline; */
}

.project-details__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
}

.projects {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 30px;
}

.project {
  max-width: 370px;
  /* -webkit-box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.25); */
  box-shadow: var(--box-shadow-grid);
  border-radius: 10px;
}

.project:hover{
  box-shadow: var(--box-shadow-grid-hover);
}

.project__img {
  border-radius: 10px;
}

.project__title {
  color: var(--text-color);
  padding: 15px 20px 25px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 31.2px */
  letter-spacing: -0.5px;
}
@media (max-width: 620px) {
  .project__title {
    font-size: 22px;
  }
}

.content-list {
  margin: 0 auto;
  max-width: 570px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  row-gap: 40px;
  text-align: center;
}
@media (max-width: 620px) {
  .content-list {
    row-gap: 20px;
  }
}

.content-list a {
  color: var(--accent);
}

.content-list__item {
  font-size: 18px;
  line-height: 1.5;
}
@media (max-width: 620px) {
  .content-list__item {
    font-size: 16px;
  }
}

.content-list__item p + p {
  margin-top: 0.5em;
}
