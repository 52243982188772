.dark-mode-btn {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 51px;
  height: 26px;
  padding: 5px;
  border-radius: 50px;
  background-color: #272727;
}
@media (max-width: 650px) {
  .dark-mode-btn {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
}

.dark-mode-btn::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--text-white);
  -webkit-transition: left 0.2s ease-in;
  transition: left 0.2s ease-in;
}

.dark-mode-btn--active::before {
  left: 26px;
}

.dark-mode-btn__icon {
  position: relative;
  z-index: 9;
}
