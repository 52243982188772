.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
  z-index: 0;
}

.modal.active {
  pointer-events: all;
  opacity: 1;
  z-index: 999;
}

.modal__content {
  color: var(--text-color);
  padding: 20px;
  border-radius: 12px;
  background-color: var(--page-bg);
  width: 50%;
  transform: scale(0.5);
  transition: 0.4s all;
}
.modal__content.active {
  transform: scale(1);
}

@media (max-width: 768px) {
  .modal__content {
    width: 90%;
  }
}

.btnForm {
  display: flex;
  justify-content: center;
}
